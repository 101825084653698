// import React, { useContext } from "react";
import Footer from "./Footer22";
// import Header from "./Header";
import Hero from "./homeHero";
// import About from "./About22";
// import Results from "./Results22";
// import Summary from "./Summary22";
// import { AppContext } from "../contexts/App";

function Home() {
  // const { aboutPageVisible } = useContext(AppContext);

  return (
    <>
      <Hero />
    </>
  );
}

export default Home;
